import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import imagen from './imagenes/hombre.png';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			codigo : "",
			registro :
			{
				grupo : '',
				apellido_paterno : '',
				apellido_materno : '',
				nombre : '',
			},
			foto : '',
			conectado : false,
			version: 1             // 1 - Checador de entrada,  2 - Checador de salida
		}
	}

	componentDidMount()
	{
	
		var { conectado, foto } = this.state;
		let temp = this;

		request.get(`/ProbarConexion`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				conectado = true;
	 			temp.setState({ conectado : conectado });
		 	}
		})
		.catch(error => {} );

		foto=imagen;

		this.setState({ conectado : conectado, foto : foto });
	}

	submit=(evt)=>
	{
		evt.preventDefault();
		var { codigo } = this.state;
		this.RegistrarEntrada(codigo);
		codigo="";
		this.setState({ codigo : codigo });
	}

	RegistrarEntrada=(cod)=>
	{
		var { registro, foto, version } = this.state;
		let temp = this;

		registro.grupo = '';
		registro.apellido_paterno = '';
		registro.apellido_materno = '';
		registro.nombre = '';

		if(version == 1)
		{
			request.get(`/RegistrarMovimiento/${cod}`)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
			 			registro = response.data;
			 			temp.setState({ registro : registro });
			 		}
			 		else
			 		{
		 				Swal.fire({
							position: 'center',
							icon: 'error',
							title: 'No Registrado',
							showConfirmButton: false,
							timer: 1500
						});
			 			temp.setState({ registro : registro });
			 		}
			 	}
			})
			.catch(error => {} );
		}
		else
		{
			request.get(`/RegistrarSalida/${cod}`)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
			 			registro = response.data;
			 			temp.setState({ registro : registro });
			 		}
			 		else
			 		{
		 				Swal.fire({
							position: 'center',
							icon: 'error',
							title: 'No Registrado',
							showConfirmButton: false,
							timer: 1500
						});
			 			temp.setState({ registro : registro });
			 		}
			 	}
			})
			.catch(error => {} );
		}

		request_file().post(`/MostrarImagen?codigo=${cod}`)
		.then(response =>
		{
			if(response.data)  
			{
				const file = new Blob([response.data], {type: 'image/jpeg' });
				if(file.size !== 0)
				{
					const fileURL = URL.createObjectURL(file);
					foto=fileURL;
					temp.setState({ foto : foto });
				}
				else
				{
					foto=imagen;
					temp.setState({ foto : foto });
				}
			}
			else
			{
				foto=imagen;
				temp.setState({ foto : foto });
			}
		})
		.catch(error => { });

		this.setState({ foto : foto });
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { codigo } = this.state;
		codigo = value;
		this.setState({ codigo : codigo });
	}

	render()
	{
		let { conectado, registro, foto, codigo, version } = this.state;

		return (
			<div className="body">

				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-12">
							<Form onSubmit={this.submit}>
								<Row>
									<Col>
										{
											version == 1?
												<div className="pl-1 pr-1 input-group mb-1 loginForm">
													<span className="input-group-text alert-success" id="basic-addon1"> REGISTRO DE ENTRADA </span>
													<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" value={codigo} aria-label="codigo" name="codigo" id="codigo" aria-describedby="basic-addon1" onChange={this.handleInputChange} />
													<span className="input-group-text alert-success">CBTIS #41</span>*/}
												</div>
											:
												<div className="pl-1 pr-1 input-group mb-1 loginForm">
													<span className="input-group-text alert-danger" id="basic-addon1"> REGISTRO DE SALIDA </span>
													<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" value={codigo} aria-label="codigo" name="codigo" id="codigo" aria-describedby="basic-addon1" onChange={this.handleInputChange} />
													<span className="input-group-text alert-danger">CBTIS #41</span>
												</div>
										}
									</Col>
								</Row>
							</Form>
				
							{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 mb-1 loginForm">
										<table>
											<tbody>
												<tr className="ml-2">
													<td className="f">
														<img className="cuadroFoto" align="middle" src={foto} alt="..."/>
													</td>

													<td className="datos">
														<span className="ne b pl-3 xl"> {registro.apellido_paterno} </span>
														<br/>
														<span className="ne b pl-3 xl"> {registro.apellido_materno} </span>
														<br/>
														<span className="ne b pl-3 xl"> {registro.nombre} </span>
														<br/>
														<span className="ne b pl-3 xl"> {registro.grupo} </span>
														<br/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
							
						</Col>
					</Row>
				</div>
				
			</div>
		);
	}
}

export default App;